<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link :to="{name:'login'}" class="brand-logo">
        <h2 class="brand-text text-primary ml-1">
          FiqOn
        </h2>
        <b-img
          fluid
          class="fiqon-logo" 
          :class="logoUrl ? '' : 'light-logo' "
          :src="logoImg"
          alt="Login V2"
        />
      </b-link>
      <!-- /Brand logo-->

      
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="dark-light-icon bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
          <!-- <dark-Toggler class="d-none d-lg-block" /> -->
        </div>
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <Animation animationType="password" style="max-width: 55%;"/>
        </div>
      </b-col>
      <!-- /Left Text-->


      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('forgot_password.forgot_password_message') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('forgot_password.inform_email') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                :label="$t('forgot_password.email_label')"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    :placeholder="$t('forgot_password.email_placeholder')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{ $t('forgot_password.send_button') }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'login'}">
              <feather-icon icon="ChevronLeftIcon" /> 
                {{ $t('forgot_password.back_to_login') }}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import store from '@/store/index'
import Animation from '@/layouts/components/Animation.vue'
import custom from "@/custom";
import { makeToast } from '@/layouts/components/Popups'


export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    Animation,
    DarkToggler,
  },
  data() {
    return {
      userEmail: '',
      logoImg: require('@/assets/images/logo/logo.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    logoUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        return false
      }
      return true
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          makeToast(custom.infoMessages.reset_password_solicitation)            
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
  .light-logo{
    filter: brightness(0) invert(1);
  }
  .brand-name{
    color: $primary;
  }
  .dark-light-icon{
    position:absolute;
    right:20px;
    top  :10px;
  }
</style>
